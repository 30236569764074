import { Link } from "gatsby";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const FaqSection = ({
	heading,
	content,
	faqData,
	ctaHeading,
	ctaSubheading,
	ctaBtn,
}) => {
	return (
		<section className="py-5 py-md-8">
			<Container>
				<Row className="justify-content-center faq-wrapper">
					<Col lg={7}>
						<h2 className="text-center montserrat-black text-light-black">
							{heading}
						</h2>
						<p className="text-center text-light-black">{content}</p>
						{(faqData && faqData.length) > 0 && (
							<Row className="justify-content-center mt-7 faq-wrapper">
								{faqData.map((faqItems, i) => (
									<Col lg={12}>
										<h6 className="text-light-black">
											{faqItems.questionText}
										</h6>
										<div
											className="fs-6"
											dangerouslySetInnerHTML={{
												__html: faqItems.answerContent,
											}}
										></div>
									</Col>
								))}
							</Row>
						)}

						<div className="text-center pt-7">
							<h3 className="montserrat-black text-light-black">
								{ctaHeading}
							</h3>
							<p className="text-light-black">{ctaSubheading}</p>
							<Button
								as={Link}
								href={ctaBtn?.url}
								size="lg"
								className="px-4 py-3 me-3 montserrat-bold fs-6 mx-auto text-light-black"
								target={ctaBtn?.target}
								variant="secondary"
							>
								{ctaBtn?.title}
							</Button>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default FaqSection;
